<template>
    <div class="div-certification">
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <a :href="certification.pivot.url" target="_blank" v-if="!(certification.pivot.url.endsWith('.jpg') || certification.pivot.url.endsWith('.png') || certification.pivot.url.endsWith('.jpeg'))">
                    <v-avatar class="mt-2 mr-2 item-custom" v-bind="attrs" v-on="on" size="60"> 
                        <v-img :src="certification.image_certification" :lazy-src="certification.image_certification"
                            :alt="certification.name" class="certification-icon-card" v-bind="attrs" v-on="on" />
                    </v-avatar>
                </a>
                <v-avatar v-else class="mt-2 mr-2 item-custom" size="60" v-bind="attrs" v-on="on" @click="openDialog">
                    <v-img :src="certification.image_certification" :lazy-src="certification.image_certification"
                        :alt="certification.name" class="certification-icon-card" v-bind="attrs" v-on="on" />
                </v-avatar>
            </template>

            <span>{{ certification.name }}</span>
        </v-tooltip>
        <v-dialog v-model="dialog" width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title class="text-subtitle-1 text-md-h5">{{ certification.name }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn class="hidden-sm-and-down" icon dark @click="closeDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text>
                    <v-img max-width="500px" min-width="200px" max-height="500px" min-height="200px"
                        :src="certification.pivot.url" :lazy-src="certification.pivot.url"
                        :alt="certification.name" class="certification-icon-card" />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    name: "ItemCertification",
    props: ["certification"],
    data(){
        return {
            dialog: false,
        }
    },
    methods: {
        openDialog(){
            this.dialog = true;
        },
        closeDialog(){
            this.dialog = false;
        }
    }
}
</script>
<style>
.certification-image{
    border-radius: 0.4rem;
}
.item-custom{
    cursor: pointer;
}
.div-certification{
    width: min-content;
    display: inline-block;
}
</style>