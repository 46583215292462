<template>
    <AssitingLivingProfileComponent @update-title="changeTitle" />
</template>

<script>
import AssitingLivingProfileComponent from "@/components/assiting-living/AssitingLivingProfileComponent";

export default {
    name: "AssitingLivingProfile",
    title: "Perfil del Assisting Living | Baja California Health Tourism",
    components: { 
        AssitingLivingProfileComponent 
    },
    methods: {
        changeTitle(e) {
            this.title = e;
            const title = document.getElementById("title-site");
            title.textContent = `${e} | Baja California Health Tourism`;
        }
    }
};
</script>

<style scoped></style>
